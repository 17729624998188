import React, { useState } from "react";
import FaqsEntry from "../components/FaqEntry";
import Layout from "../components/page/Layout";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Link } from "gatsby";

export type FaqEntryType = {
  question: string;
  answer: string;
  index?: number;
};

const faq = [
  {
    question: "Ist rap-tool.de kostenlos?",
    answer:
      "In der Evaluierungsphase kann jeder das Tool kostenlos benutzen. In Zukunft wird es bezahlte Features geben, ebenso können einige der jetzt kostenlos verfügbaren Funktionen in Zukunft nur gegen Gebühr verfügbar werden. Konkrete Pläne zur Ausgestaltung von Preismodellen und dahinterliegenden Funktionen gibt es aber noch nicht.",
  },
  {
    question: "Darf ich rap-tool.de für gewerbliche Zwecke nutzen?",
    answer: "Ja. Sonst macht das ja gar keinen Sinn. Auch die kostenlosen Funktionen können für gewerbliche Zwecke verwendet werden.",
  },
  {
    question: "Warum fehlen einige Funktionen, die ich von einer solchen Sofware erwarte?",
    answer:
      "rap-tool.de wird kontinuierlich weiterentwickelt. Wir haben das Tool bewusst in dem Zustand zur Verfügung gestellt, der den Nutzern bereits einen Vorteil bringt, ohne aber gleich alle Funktionalitäten abzudecken. Auf diese Art können wir früh Feedback von unseren Nutzern sammeln und die Weiterentwicklung mit Ihnen begleiten. Bitte helfen Sie uns durch Ihre Rückmeldungen, die Plattform weiterhin in Ihrem Sinne zu verbessern.",
  },
  {
    question: "Gibt es Einschränkungen bei der Nutzung der Funktionen?",
    answer:
      "Derzeit nicht, aber vielleicht in Zukunft. Insbesondere in der Evaluierungsphase können technische Beschränkungen auferlegt werden, die die Anzahl und die Datenmenge von Abfragen begrenzen. Ebenso ist eine dauerhafte Verfügbarkeit des Services nicht garantiert.",
  },
].map((entry, index) => {
  return { index: index, ...entry };
}); // We add the index to each FAQ entry. This is important for the list key.

const faqMatchesSearch = (faq_entry: FaqEntryType, search: string) => {
  var result = false;
  var question = faq_entry.question.toLowerCase();
  var answer = faq_entry.answer.toLowerCase();
  search.split(" ").forEach((word) => {
    let wordLower = word.toLowerCase();
    if (question.includes(wordLower) || answer.includes(wordLower)) {
      result = true;
    }
  });
  return result;
};

// markup
const Page = () => {
  const [state, setState] = useState({ faqs: faq });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState({
      faqs: faq.filter((entry) => faqMatchesSearch(entry, e.currentTarget.value)),
    });
  }

  return (
    <div>
      <h2>Häufige Fragen (FAQ)</h2>
      <input className="form-control mx-4 my-4" type="text" placeholder="Filter" onChange={handleChange} />
      <TransitionGroup className="noclass">
        {state.faqs.map(({ question, answer, index }) => (
          <CSSTransition key={index} timeout={500} classNames="item">
            {/* <div>{question}</div> */}
            <FaqsEntry question={question} answer={answer} />
          </CSSTransition>
        ))}
      </TransitionGroup>

      <p className="mt-3 border-t-2">
        Haben Sie die Antwort auf Ihre Fragen nicht gefunden? Bitte <Link to="/kontakt">kontaktieren</Link> Sie uns.
      </p>
    </div>
  );
};

export default Page;
