import React from "react";
import { FaqEntryType } from "../pages/faq";

export default function FaqsEntry(props: FaqEntryType) {
  return (
    <div className="faq-entry">
      <h4>{props.question}</h4>
      <span>{props.answer}</span>
    </div>
  );
}
